import paymentService from "../../../../services/paymentService";
import merchantService from "../../../../services/merchantService";

export default {
    name: "account-setting",
    components: {},
    props: [],
    data() {
        return {
            selected: null,
            options: [
                { text: "تبدیل دارایی در زمان خرید", value: false },
                {
                    text: "تبدیل دارایی در زمان درخواست تسویه حساب",
                    value: true,
                },
            ],
        };
    },
    computed: {},
    mounted() { },
    created() {
        this.getMerchantSettingsFunc();
    },
    methods: {
        getMerchantSettingsFunc() {
            merchantService
                .getMerchantSettings(localStorage.getItem("bitrahAccessToken"))
                .then((response) => {
                    this.selected = response.data.calculateRialBalanceAtSettle;
                });
        },
        setAndSubmitPaymentType(type) {
            paymentService
                .setTypeOfPayment(
                    type,
                    localStorage.getItem("bitrahAccessToken")
                )
                .then(() => {
                    this.$bvToast.toast(this.$i18n.t("toast.succesfullOperation"), {
                        title: this.$i18n.t("toast.successTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "successFontColor rtl text-right font-weight-bold py-3",
                        headerClass:
                            "successBackgroundColor successFontColor  rtl text-right",
                        solid: false,
                    });
                });
        },
    },
};
